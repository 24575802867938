var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"productId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['productId',{ initialValue:'0' }]),expression:"['productId',{ initialValue:'0' }]"}],attrs:{"name":"productId"}})],1),_c('a-form-item',{attrs:{"label":"产品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {rules: [{ required: true, message: '请输入产品名称' }]}
        ]),expression:"[\n          'name',\n          {rules: [{ required: true, message: '请输入产品名称' }]}\n        ]"}],attrs:{"name":"name","placeholder":"请输入产品名称"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"类型","hidden":_vm.isHide}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderType', { initialValue: 1 }]),expression:"['orderType', { initialValue: 1 }]"}],attrs:{"name":"orderType"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("体检")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("门诊")]),_c('a-radio',{attrs:{"value":3}},[_vm._v("住院")]),_c('a-radio',{attrs:{"value":4}},[_vm._v("咨询")]),_c('a-radio',{attrs:{"value":5}},[_vm._v("其他")])],1)],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remarks',
          {rules: [{ required: true, message: '请输入描述' }]}
        ]),expression:"[\n          'remarks',\n          {rules: [{ required: true, message: '请输入描述' }]}\n        ]"}],attrs:{"name":"remarks","placeholder":"请输入描述"}})],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'listorder',
          {rules: [{ required: true, message: '请输入排序' }]}
        ]),expression:"[\n          'listorder',\n          {rules: [{ required: true, message: '请输入排序' }]}\n        ]"}],attrs:{"name":"path","placeholder":"请输入排序"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }