<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="productId"
      >
        <a-input
          v-decorator="['productId',{ initialValue:'0' }]"
          name="productId"></a-input>
      </a-form-item>

      <a-form-item
        label="产品名称"
      >
        <a-input
          v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入产品名称' }]}
          ]"
          name="name"
          placeholder="请输入产品名称"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="类型"
        :hidden="isHide"
      >
        <a-radio-group v-decorator="['orderType', { initialValue: 1 }]" name="orderType">
          <a-radio :value="1">体检</a-radio>
          <a-radio :value="2">门诊</a-radio>
          <a-radio :value="3">住院</a-radio>
          <a-radio :value="4">咨询</a-radio>
          <a-radio :value="5">其他</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="描述"
      >
        <a-textarea
          v-decorator="[
            'remarks',
            {rules: [{ required: true, message: '请输入描述' }]}
          ]"
          name="remarks"
          placeholder="请输入描述"/>
      </a-form-item>
      <a-form-item
        label="排序"
      >
        <a-input
          v-decorator="[
            'listorder',
            {rules: [{ required: true, message: '请输入排序' }]}
          ]"
          name="path"
          placeholder="请输入排序"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>

    </a-form>
  </div>
</template>

<script>

export default {
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: this.$form.createForm(this),
      isHide: false
    }
  },
  methods: {
    setHide (val) {
      this.isHide = val
    }
  }

}
</script>
